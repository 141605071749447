import { useState, useEffect } from "react";
import useWindowSize from "../../hooks/useWindowSize";

import Preloader from "./Preloader";
import Titles from "./Titles";
import Crosshair from "./Crosshair";
import Loadbar from "./Loadbar";
import Dots from "./Dots";
import MuteButton from "./MuteButton";
import Credits from "./Credits";
import Instructions from "./Instructions";
import BuyStream from "./BuyStream";
import MobileArrows from "./MobileArrows";


const UserInterface = (props) => {
  const {
    percentageLoaded,
    isMuted,
    setIsMuted,
    collectables,
    isHovered,
    hoveredName,
    distanceState,
    hoverLoadingState,
    setForward, 
    setBackward, 
    setLeft, 
    setRight,
    deviceControlsAllowed,
    setDeviceControlsAllowed,
    enterButton,
    skyState,
  } = props;

  const [preloaderVisible, setPreloaderVisible] = useState(true);
  const [helpOpen, setHelpOpen] = useState(true);

  const windowSize = useWindowSize();
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    if (windowSize.width <= 750) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [windowSize, isMobile]);

  return (
    <>
      <Titles {...{
          preloaderVisible
        }}/>
      <Preloader
        {...{
          percentageLoaded,
          setIsMuted,
          preloaderVisible,
          setPreloaderVisible,
          setDeviceControlsAllowed,
          enterButton,
        }}
      />
      <Instructions {...{
          deviceControlsAllowed,
          helpOpen,
          setHelpOpen,
        }}/>
      <Crosshair
        {...{
          isHovered,
          distanceState,
        }}
      />
      <Loadbar
        {...{
          distanceState,
          hoverLoadingState,
          hoveredName,
        }}
      />
      <Dots {...{
        collectables,
        skyState
      }} />
      <BuyStream />
      <MuteButton
        {...{
          isMuted,
          setIsMuted,
        }}
      />
      {isMobile?(
        <MobileArrows {...{
          setForward, 
          setBackward, 
          setLeft, 
          setRight,
          helpOpen
        }} />
      ):('')}
      <Credits />
    </>
  );
};

export default UserInterface;
