import { useRef, useEffect, useState } from "react";
import { useThree, useLoader } from "@react-three/fiber";
import { AudioLoader, AudioListener } from "three";

function Sound(props) {
  const { url, state, enterButton } = props;

  const sound = useRef();
  const { camera } = useThree();
  const [listener] = useState(() => new AudioListener());
  const buffer = useLoader(AudioLoader, url);

  const [isInit, setIsInit] = useState(false);

  const handleClick = () => {
    sound.current.setBuffer(buffer);
    sound.current.setRefDistance(3);
    sound.current.setLoop(true);
    setIsInit(true);
    sound.current.play();
  }

  useEffect(()=>{
    enterButton.current.addEventListener('click', handleClick);
  })

  useEffect(()=>{
    if(isInit){
          if (!state) {
        camera.add(listener);
          sound.current.play();
      } else {
        camera.remove(listener)
          sound.current.pause();
      }
    }

  }, [state, camera, listener, isInit])
    return <positionalAudio ref={sound} args={[listener]} />;
  
}

export default Sound
