import "./Credits.scss";

const Credits = () => {
  return (
    <a
      href="https://www.voidmain.studio/"
      target="_blank"
      rel="noreferrer"
      className="credits__wrapper"
    >
     {` Site by: VOID MAIN()`}
    </a>
  );
};

export default Credits;
