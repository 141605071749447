import One from "./One";
import Two from "./Two";
import Three from "./Three";
import Four from "./Four";
import Five from "./Five";

const Collectables = (props) => {
  const { isMuted, collectables, enterButton } = props;

  return (
    <>
      <One {...{
        isMuted,
        collectables,
        enterButton
      }}/>
      <Two {...{
        isMuted,
        collectables,
        enterButton
      }}/>
      <Three {...{
        isMuted,
        collectables,
        enterButton
      }}/>
      <Four {...{
        isMuted,
        collectables,
        enterButton
      }}/>
      <Five {...{
        isMuted,
        collectables,
        enterButton
      }}/>
    </>
  );
};

export default Collectables;
