import "./Crosshair.scss"

const Crosshair = (props) => {

  const {
    isHovered,
    distanceState,
  } = props;

  return (
    <>
      <div className={`crosshair__wrapper ${isHovered?'active':''}`}>
        <div className={`crosshair__corner top-left ${isHovered?'active':''}`}></div>
        <div className={`crosshair__corner top-right ${isHovered?'active':''}`}></div>
        <div className={`crosshair__corner bottom-left ${isHovered?'active':''}`}></div>
        <div className={`crosshair__corner bottom-right ${isHovered?'active':''}`}></div>
      </div>

      <div className={`crosshair__tooltip-wrapper ${distanceState!==0?'active':''}`}>
        {distanceState===1?'Move a little closer!':''}
        {distanceState===2?'Thats it... hold steady!':''}
        
      </div>
    </>
  )
}

export default Crosshair