import { useEffect } from "react";
import Home from "./views/Home";

import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

import "./styles/global-styles.scss";

function App() {

  const firebaseConfig = {
    apiKey: "AIzaSyAumzS0xr6tgedDgWuNnb5h-p-nfzCYULk",
    authDomain: "kiimi-sun-goes-down.firebaseapp.com",
    projectId: "kiimi-sun-goes-down",
    storageBucket: "kiimi-sun-goes-down.appspot.com",
    messagingSenderId: "83131081002",
    appId: "1:83131081002:web:4d66593cb42f907653be53",
    measurementId: "G-3N24BM31SP"
  };
  

  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  logEvent(analytics, 'notification_received');

  useEffect(()=>{
    const appHeight = () => {
      const doc = document.documentElement
      doc.style.setProperty('--app-height', `${window.innerHeight}px`)
     }
     window.addEventListener('resize', appHeight)
     appHeight();
     return () => window.removeEventListener("resize", appHeight);
  },[])


  return (
    <div className="App">
      <Home />
    </div>
  );
}

export default App;
