import { DeviceOrientationControls, PerspectiveCamera } from "@react-three/drei";
import LookControls from "./LookControls";

function DeviceCameraRig(props) {
    const { deviceControlsAllowed } = props;

    if(deviceControlsAllowed){
      return(
        <>
        <DeviceOrientationControls />
        <perspectiveCamera  position={[0, 2.5, 100]} />
        </>
      )
    } else {
      <>
      <LookControls />
      <PerspectiveCamera  position={[0, 2.5, 100]} makeDefault/>
      </>
    }

}

export default DeviceCameraRig;
