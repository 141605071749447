import { useProgress } from "@react-three/drei";
import { useEffect } from "react";
const LoadingManager = (props) => {
  const { setPercentageLoaded } = props;
  const { progress } = useProgress();
  useEffect(() => {
    setPercentageLoaded(progress);
  }, [progress,setPercentageLoaded]);
  return;
};

export default LoadingManager;
