import "./MuteButton.scss";

const MuteButton = (props) => {
  const { isMuted, setIsMuted } = props;
  const handleIsMuted = () => {
    setIsMuted((isMuted) => !isMuted);
  };

  return (
    <div className="mute-button__wrapper">
      <div>Sound:</div>
      <button onClick={handleIsMuted} className={"mute-button__outer"}>
        <div className={`mute-button__inner`} 
          style={{left: isMuted ? 0 : "50%"}}/>
        <div className="mute-button__text off">Off</div>
        <div className="mute-button__text on">On</div>
      </button>
    </div>
  );
};

export default MuteButton;
