import { useEffect, useState } from "react";
import "./Preloader.scss";
import useWindowSize from "../../hooks/useWindowSize";
const Preloader = (props) => {
  const { 
    percentageLoaded, 
    setIsMuted,
    preloaderVisible,
    setPreloaderVisible,
    setDeviceControlsAllowed,
    enterButton
 } = props;

 const windowSize = useWindowSize();
 const [isMobile, setIsMobile] = useState(false);
 const [removePointerEvents, setRemovePointerEvents] = useState(false);

 useEffect(() => {
   if (windowSize.width <= 750) {
     setIsMobile(true);
   } else {
     setIsMobile(false);
   }
 }, [windowSize, isMobile]);
  const [ready, setReady] = useState(false);

  const handleClick = () => {
    setIsMuted(false);
    setPreloaderVisible(false);
    setRemovePointerEvents(true);

    if(isMobile){
      if (typeof DeviceOrientationEvent.requestPermission === 'function') {
        DeviceOrientationEvent.requestPermission()
          .then(permissionState => {
            if (permissionState === 'granted') {
              // window.addEventListener('deviceorientation', () => {});
              setDeviceControlsAllowed(true);
              console.log(true);
            } else {
              setDeviceControlsAllowed(false);
              console.log(false);
            }
          })
          .catch(console.error);
      } else {
        setDeviceControlsAllowed(true);
        console.log('ther true');
      }
    }
  };

  useEffect(() => {
    if (percentageLoaded === 100) {
      setReady(true);
    }
  }, [percentageLoaded]);

  return (
    <div className={`preloader__wrapper ${preloaderVisible ? "active" : ""}`}>
        <div className="preloader__gradient-1" />
        <div className="preloader__gradient-2" />
        <div className="preloader__gradient-3" />
      <div className={`preloader__loading-wrapper`}>
        <div className={`preloader__loading-text ${ready ? "disabled" : ""}`}>Loading...</div>
        <div className={`preloader__loadbar-outer`}>
          <div
            className={`preloader__loadbar-inner ${ready ? "done" : ""}`}
            style={
              { 
                width: `calc(${percentageLoaded}% - 3px`,
                pointerEvents: removePointerEvents?'none':''
             }
            }
            onClick={handleClick}
            ref={enterButton}
          >
            <div className={`preloader__enter-button ${ready ? "active" : ""}`}>
              ENTER
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Preloader;
