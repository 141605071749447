import { useFrame, useThree } from "@react-three/fiber";
import { Vector3 } from "three";

const vec = new Vector3();

const TouchControls = (props) => {
  const { forward, backward, left, right } = props;

  const { camera } = useThree();

  const moveForward = (distance) => {
    vec.setFromMatrixColumn(camera.matrix, 0);
    vec.crossVectors(camera.up, vec);
    camera.position.addScaledVector(vec, distance);
  };
  const moveRight = (distance) => {
    vec.setFromMatrixColumn(camera.matrix, 0);
    camera.position.addScaledVector(vec, distance);
  };

  useFrame((_, delta)=>{
    const speed = 15;
    if(forward){
        moveForward(delta * speed);
    }
    if(backward){
        moveForward(-delta * speed)
    }
    if(left){
        moveRight(-delta * speed)
    }
    if(right){
        moveRight(delta * speed)
    }
  });

  return null;
};

export default TouchControls;
