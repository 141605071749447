import { useRef, useState } from "react";
import { useGLTF } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";

import Chrome from "../textures/Chrome";
import Sound from "./Sound";

const Four = (props) => {
  const { collectables, isMuted, enterButton } = props;
  const [index] = useState(3);

  const group = useRef();
  const { nodes } = useGLTF("./assets/models/four.gltf");

  useFrame(({ clock }) => {
    const t = clock.getElapsedTime();
    if (collectables[index].isComplete) {
      group.current.rotation.y += 0.01;
      group.current.position.y = group.current.position.y + Math.sin(t) * 0.004;
    }
  });

  return (
    <group
      ref={group}
      position={[37, 4, -13]}
      rotation={[0, -Math.PI / 2, 0]}
      scale={[
        collectables[index].percentageHovered / 200 + 0.6,
        collectables[index].percentageHovered / 200 + 0.6,
        collectables[index].percentageHovered / 200 + 0.6,
      ]}
    >
      <mesh
        userData={{
          index: index,
        }}
        visible={false}
      >
        <sphereGeometry args={[4, 32, 16]} />
      </mesh>
      <mesh
        name="Cylinder007"
        castShadow
        receiveShadow
        geometry={nodes.Cylinder007.geometry}
        userData={{
          index: index,
        }}
      >
        <Chrome emissive={collectables[index].isComplete} />
        <Sound 
        url={"./assets/audio/Stem_Vocals.mp3"} 
        state={isMuted}
        enterButton={enterButton}
        />
      </mesh>
    </group>
  );
};

export default Four;
useGLTF.preload("./assets/models/four.gltf");
