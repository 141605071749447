import { useRef, useState } from "react";
import { useGLTF } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";

import Chrome from "../textures/Chrome";
import Sound from "./Sound";

const One = (props) => {
  const { isMuted, collectables, enterButton } = props;
  const [index] = useState(0);
  
  const group = useRef();
  const { nodes } = useGLTF("./assets/models/one.gltf");

  useFrame(({ clock }) => {
    const t = clock.getElapsedTime();
    if (collectables[index].isComplete) {
      group.current.rotation.y += 0.01;
      group.current.position.y = group.current.position.y + Math.sin(t) * 0.004;
    }
  });

  return (
    <group
      ref={group}
      position={[-25, 4, 30]}
      rotation={[0, -Math.PI / 2, 0]}
      scale={[
        collectables[index].percentageHovered / 200 + 0.6,
        collectables[index].percentageHovered / 200 + 0.6,
        collectables[index].percentageHovered / 200 + 0.6,
      ]}
    >
      <mesh
        userData={{
          index: index,
        }}
        visible={false}
      >
        <sphereGeometry args={[3, 32, 16]} />
      </mesh>
      <mesh
        name="Sphere013"
        castShadow
        receiveShadow
        geometry={nodes.Sphere013.geometry}
        userData={{
          index: index,
        }}
      >
        <Chrome emissive={collectables[index].isComplete} />
        {/* {!isMuted?( */}
        <Sound 
        url={"./assets/audio/Stem_Bass.mp3"} 
        state={isMuted}
        enterButton={enterButton}
        />
        {/* ):("")} */}

      </mesh>
    </group>
  );
};

export default One;
useGLTF.preload("./assets/models/one.gltf");
