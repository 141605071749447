import "./MobileArrows.scss";

const MobileArrows = (props) => {
  const { setForward, setBackward, setLeft, setRight, helpOpen } = props;
  return (
    <div className={`mobile-arrows__wrapper ${!helpOpen?'active':''}`}>
      <div className="mobile-arrows__row">
        <button
          className="mobile-arrows__key"
          onPointerDown={() => setForward(true)}
          onPointerUp={() => setForward(false)}
        >
          <div className="mobile-arrows__key-inner">
            <span className="mobile-arrows__character">&uarr;</span>
          </div>
        </button>
      </div>
      <div className="mobile-arrows__row">
        <button
          className="mobile-arrows__key"
          onPointerDown={() => setLeft(true)}
          onPointerUp={() => setLeft(false)}
        >
          <div className="mobile-arrows__key-inner">
            <span className="mobile-arrows__character">&larr;</span>
          </div>
        </button>
        <button
          className="mobile-arrows__key"
          onPointerDown={() => setBackward(true)}
          onPointerUp={() => setBackward(false)}
        >
          <div className="mobile-arrows__key-inner">
            <span className="mobile-arrows__character">&darr;</span>
          </div>
        </button>
        <button
          className="mobile-arrows__key"
          onPointerDown={() => setRight(true)}
          onPointerUp={() => setRight(false)}
        >
          <div className="mobile-arrows__key-inner">
            <span className="mobile-arrows__character">&rarr;</span>
          </div>
        </button>
      </div>
    </div>
  );
};

export default MobileArrows;
