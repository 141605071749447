import React, { useRef, useMemo } from "react";
import { extend, useThree, useLoader, useFrame } from "@react-three/fiber";
import { TextureLoader, CircleGeometry, Vector3, RepeatWrapping } from "three";
import { Water } from "three/examples/jsm/objects/Water.js";

extend({ Water });

function Ocean() {
  const ref = useRef();
  const gl = useThree((state) => state.gl);
  const waterNormals = useLoader(
    TextureLoader, "./assets/textures/waternormals.jpg"
  );


  waterNormals.wrapS = waterNormals.wrapT = RepeatWrapping;
  const geom = useMemo(() => new CircleGeometry(19.65, 5, 0.3), []);
  const config = useMemo(
    () => ({
      textureWidth: 232,
      textureHeight: 232,
      waterNormals,
      sunDirection: new Vector3(),
      sunColor: 0xeb8934,
      // waterColor: 0x0064b5,
      waterColor: 0x000000,
      distortionScale: 5,
      fog: false,
      format: gl.encoding,
    }),
    [waterNormals, gl.encoding]
  );

  useFrame(
    (state, delta) => (ref.current.material.uniforms.time.value += delta * 0.25)
  );


  return (
    <water
      ref={ref}
      args={[geom, config]}
      rotation={[-Math.PI / 2, 0, 0]}
      position={[0, 1, 0]}
    />
  );
}

export default Ocean;